import * as React from 'react';

export const LoadingContext = React.createContext({
  showLoading: () => {},
  hideLoading: () => {},
});

export const useLoading = () => {
  const { showLoading, hideLoading } = React.useContext(LoadingContext);

  return { showLoading, hideLoading };
};
