import React from 'react';
import Loadable from 'react-loadable';
import Loading from '../Loading';

const LoadableComponent = (component) =>
  Loadable({
    loader: component,
    loading: () => <Loading show />,
    delay: 1000,
  });

export default LoadableComponent;
