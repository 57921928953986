import React, { useEffect } from 'react';
import LogoFooterImage from '@assets/images/logo-footer.png';

const Footer = () => {
  useEffect(() => {}, []);

  return (
    <footer className="footer" id="footer-contact">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="logo">
                <img src={LogoFooterImage} alt="MHH" />
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-12 col-md-5">
                  <div className="footer-link">
                    <div className="head">contact</div>
                    <div className="list">
                      <div className="item">
                        <div className="icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_3417_4086)">
                              <path
                                d="M9.86331 4.23315C7.84402 4.23315 6.20117 5.876 6.20117 7.8953C6.20117 9.9146 7.84402 11.5574 9.86331 11.5574C11.8826 11.5574 13.5255 9.9146 13.5255 7.8953C13.5255 5.876 11.8827 4.23315 9.86331 4.23315ZM9.86331 10.0155C8.69424 10.0155 7.74311 9.06437 7.74311 7.8953C7.74311 6.72622 8.69424 5.7751 9.86331 5.7751C11.0324 5.7751 11.9835 6.72622 11.9835 7.8953C11.9835 9.06437 11.0324 10.0155 9.86331 10.0155Z"
                                fill="#DE5D2F"
                              />
                              <path
                                d="M9.86348 -0.00708008C5.506 -0.00708008 1.96094 3.53802 1.96094 7.89547V8.11392C1.96094 10.3177 3.2244 12.8858 5.71636 15.7467C7.52283 17.8207 9.30407 19.2669 9.37896 19.3274L9.86348 19.7189L10.348 19.3275C10.4229 19.2669 12.2042 17.8207 14.0106 15.7467C16.5025 12.8858 17.766 10.3177 17.766 8.11396V7.8955C17.766 3.53802 14.221 -0.00708008 9.86348 -0.00708008ZM16.2241 8.11396C16.2241 11.8382 11.4255 16.3465 9.86348 17.7151C8.30108 16.3461 3.50288 11.8379 3.50288 8.11396V7.8955C3.50288 4.38828 6.35626 1.5349 9.86348 1.5349C13.3707 1.5349 16.2241 4.38828 16.2241 7.8955V8.11396Z"
                                fill="#DE5D2F"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3417_4086">
                                <rect
                                  width="19.726"
                                  height="19.726"
                                  fill="white"
                                  transform="translate(0 -0.00683594)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text">
                          Address: Paya Lebar Central Square, 60 Paya Lebar Rd,
                          #07-54, Singapore 409051
                        </div>
                      </div>
                      {/* <div className="item">
                        <div className="icon">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.178 20.4863C13.5412 20.4863 12.9099 20.3737 12.2925 20.1494C9.57266 19.1614 7.04806 17.5522 4.99156 15.4957C2.93505 13.4392 1.32582 10.9146 0.33783 8.19473C0.0203382 7.32066 -0.0733566 6.41839 0.0594419 5.51288C0.183842 4.66469 0.51239 3.84128 1.0096 3.13163C1.50901 2.41887 2.17482 1.82603 2.93501 1.41723C3.74567 0.981315 4.63049 0.760254 5.56497 0.760254C5.85561 0.760254 6.1068 0.963324 6.16771 1.24749L7.13525 5.76268C7.17905 5.96713 7.11622 6.17987 6.96839 6.32773L5.31513 7.98096C6.87474 11.0817 9.40542 13.6124 12.5062 15.172L14.1594 13.5188C14.3073 13.3709 14.5201 13.3082 14.7245 13.3519L19.2397 14.3195C19.5239 14.3804 19.7269 14.6316 19.7269 14.9222C19.7269 15.8567 19.5058 16.7415 19.0699 17.5522C18.6611 18.3124 18.0683 18.9782 17.3555 19.4776C16.6459 19.9748 15.8225 20.3034 14.9742 20.4278C14.7085 20.4668 14.4427 20.4862 14.178 20.4863ZM5.07246 2.02005C3.83983 2.15612 2.74577 2.8022 2.01925 3.83912C1.20227 5.00511 1.01176 6.43923 1.49657 7.77384C3.39605 13.0028 7.48445 17.0912 12.7134 18.9907C14.048 19.4755 15.4821 19.285 16.6481 18.468C17.685 17.7415 18.3311 16.6474 18.4672 15.4148L14.7941 14.6277L13.0675 16.3543C12.8838 16.538 12.6046 16.5868 12.3696 16.4763C8.70228 14.7534 5.73379 11.785 4.01092 8.11768C3.90047 7.88256 3.94924 7.6034 4.13297 7.41971L5.85954 5.69314L5.07246 2.02005Z"
                              fill="#DE5D2F"
                            />
                          </svg>
                        </div>
                        <div className="text">Hotline: xxx xxx xxxx</div>
                      </div> */}
                      <div className="item">
                        <div className="icon">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_3417_4100)">
                              <path
                                d="M17.4144 3.14746H2.31164C1.037 3.14746 0 4.18446 0 5.4591V15.3221C0 16.5968 1.037 17.6338 2.31164 17.6338H17.4144C18.689 17.6338 19.726 16.5968 19.726 15.3221V5.4591C19.726 4.18446 18.689 3.14746 17.4144 3.14746ZM18.1849 15.3221C18.1849 15.747 17.8393 16.0927 17.4144 16.0927H2.31164C1.88676 16.0927 1.5411 15.747 1.5411 15.3221V5.4591C1.5411 5.03422 1.88676 4.68856 2.31164 4.68856H17.4144C17.8393 4.68856 18.1849 5.03422 18.1849 5.4591V15.3221Z"
                                fill="#DE5D2F"
                              />
                              <path
                                d="M18.0544 4.10791L9.86332 10.2005L1.67224 4.10791L0.752441 5.34444L9.86332 12.1212L18.9742 5.34444L18.0544 4.10791Z"
                                fill="#DE5D2F"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3417_4100">
                                <rect
                                  width="19.726"
                                  height="19.726"
                                  fill="white"
                                  transform="translate(0 0.527344)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text">
                          Email:{' '}
                          <a href="mailto:sales@mhh.com.sg">sales@mhh.com.sg</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="follow">
                    <div className="head">Follow to receive news</div>
                    <div className="group-btn">
                      <input placeholder="Your email" />
                      <button>Follow</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="text">
              © 2024, MHH Singapore, All Right Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
