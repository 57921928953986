import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AppUtil } from '@utils';

export const usePageTitle = () => {
  const { pathname } = useLocation();

  const pageTitle = useMemo(() => {
    return AppUtil.getPageTitle(pathname);
  }, [pathname]);

  return { pageTitle };
};

export default usePageTitle;
