/* eslint-disable no-unused-vars */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, vi } from '@assets/lang';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    vi: {
      translation: vi,
    },
  },
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});
export default i18next;
