import React from 'react';
import { Button } from 'antd';

const CommonButton = (props) => {
  const { classNameProps, icon, title, onClick, typeProps, loading } = props;

  return (
    <Button
      {...typeProps}
      className={
        classNameProps
          ? `d-flex align-items-center justify-content-center ${classNameProps}`
          : `d-flex align-items-center justify-content-center`
      }
      onClick={() => onClick()}
      loading={loading || false}>
      {icon && icon}
      {title}
    </Button>
  );
};

export default CommonButton;
