import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import InputPassword from './InputPassword';

const FormGroup = React.forwardRef((props, ref) => {
  const { label, password, required, errors, ...restProp } = props;

  return (
    <>
      {label && (
        <label>
          {label} {required && <span className="label-required">*</span>}{' '}
        </label>
      )}
      <div className="form-group">
        {password && <InputPassword errors={errors} ref={ref} {...restProp} />}
        {!password && <Input errors={errors} ref={ref} {...restProp} />}
      </div>
    </>
  );
});

FormGroup.propTypes = {
  label: PropTypes.string,
  password: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.string,
};

FormGroup.defaultProps = {
  required: false,
};

FormGroup.displayName = 'FormGroup';
export default FormGroup;
