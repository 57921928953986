import LoadableComponent from '@components/Loadable';
import { PAGE_URL } from '@/config/AppConst';

// When user is logged, user go to page has restrict = true app auto redirect to Home Page
// Using restrict = false. Keep this page.
export const PublicRoutes = [
  {
    path: '/',
    title: 'mhh',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
  {
    path: PAGE_URL.HOME,
    title: 'mhh',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
  {
    path: PAGE_URL.ABOUT,
    title: 'About us',
    component: LoadableComponent(() => import('@screens/About')),
  },
  {
    path: PAGE_URL.PRODUCT,
    title: 'Product',
    component: LoadableComponent(() => import('@screens/Product')),
  },
  {
    path: PAGE_URL.NEWS,
    title: 'News',
    component: LoadableComponent(() => import('@screens/News')),
  },
  {
    path: PAGE_URL.CONTACT,
    title: 'Contact',
    component: LoadableComponent(() => import('@screens/Contact')),
  },
];

export default [...PublicRoutes];
