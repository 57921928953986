import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropsType from 'prop-types';
import ModalBootStrap from 'react-bootstrap/Modal';
import Button from '../Button';
import Loading from '../Loading';

const Modal = React.forwardRef((props, ref) => {
  const {
    show,
    beforeClose,
    beforeOpen,
    afterOpen,
    refreshModalBeforeClose,
    afterClose,
    children,
    showIconClose,
    showLoading,
    onBack,
    ...restProps
  } = props;

  const [_visible, setVisible] = useState(false);
  const [_loading, setShowLoading] = useState(false);

  const [key, setKey] = useState(new Date().getTime());

  const isHasOpenModalRef = useRef(false);

  const handleEventModal = useCallback(
    async (state) => {
      if (!state && beforeClose) await beforeClose();
      if (state && beforeOpen) await beforeOpen();

      setVisible(state);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useImperativeHandle(ref, () => ({
    show: () => handleEventModal(true),
    hide: () => handleEventModal(false),
    toggle: (state) => {
      if (state !== undefined) {
        handleEventModal(state);
      } else {
        handleEventModal(!_visible);
      }
    },
    forceRender: () => setKey(new Date().getTime()),
    showLoading: () => setShowLoading(true),
    hideLoading: () => setShowLoading(false),
  }));

  useEffect(() => {
    if (show === undefined) return;

    handleEventModal(show);
  }, [handleEventModal, show]);

  useEffect(() => {
    let timer;

    if (_visible) isHasOpenModalRef.current = true;

    if (!_visible && isHasOpenModalRef.current && afterClose) {
      afterClose();
    }

    if (_visible && afterOpen) {
      afterOpen();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_visible]);

  useEffect(() => {
    if (showLoading === undefined) return;

    setShowLoading(showLoading);
  }, [showLoading]);

  const handleBack = () => {
    setVisible(false);

    if (onBack) onBack();
  };

  return (
    <ModalBootStrap show={_visible} {...restProps}>
      <Loading wrapperClassName="sprint-modal-root" show={_loading} />

      <React.Fragment key={key}>
        {onBack && (
          <Button
            type="button"
            className="btn btn-goback"
            title="Go Back"
            onClick={handleBack}>
            <i className="fa fa-caret-left"></i>
          </Button>
        )}
        {showIconClose && (
          <Button
            onClick={() => setVisible(false)}
            type="button"
            className="btn btn-close">
            <i className="fa fa-times"></i>
          </Button>
        )}
        {children}
      </React.Fragment>
    </ModalBootStrap>
  );
});

Modal.propTypes = {
  show: PropsType.bool,
  beforeClose: PropsType.func,
  afterClose: PropsType.func,
  beforeOpen: PropsType.func,
  afterOpen: PropsType.func,
  refreshModalBeforeClose: PropsType.bool, // Force render clear data of modal before close
  showIconClose: PropsType.bool, // Only use for case not provide show in props. If provide show && showIconClose, you need provide afterClose and re-set state at parent component,
  showLoading: PropsType.bool,
  onBack: PropsType.func,
};

Modal.displayName = 'Modal';
export default Modal;
