import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config/AppConst';

export default {
  storeToken: (token) => window.localStorage.setItem(TOKEN_KEY, token),
  storeRefreshToken: (token) =>
    window.localStorage.setItem(REFRESH_TOKEN_KEY, token),
  removeToken: () => window.localStorage.removeItem(TOKEN_KEY),
  removeRefreshToken: () => window.localStorage.removeItem(REFRESH_TOKEN_KEY),
  getToken: () => window.localStorage.getItem(TOKEN_KEY),
  getRefreshToken: () => window.localStorage.getItem(REFRESH_TOKEN_KEY),
  isValidAccessToken: () => !!window.localStorage.getItem(TOKEN_KEY),
};
