import React from 'react';
import PropsType from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

const Loading = (props) => {
  const { show, wrapperClassName, ...restProps } = props;

  if (!show) return;

  return (
    <div className={`spinner-root ${wrapperClassName || ''}`}>
      <Spinner animation="border" variant="success" {...restProps}></Spinner>
      <div className="mt-3">Loading...</div>
    </div>
  );
};

Loading.propTypes = {
  show: PropsType.bool,
  wrapperClassName: PropsType.string,
};

export default Loading;
