import React, { useMemo, useState } from 'react';
import Input from './Input';
import { EyeOutlined } from '@ant-design/icons';

const InputPassword = React.forwardRef((props, ref) => {
  const { errors, className, ...restProps } = props;

  const [type, setType] = useState('password');

  const toggleType = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  const classNameInput = useMemo(() => {
    if (!errors) return className;

    return className + ' form-control-invalid';
  }, [className, errors]);

  return (
    <>
      <div className="position-relative">
        <Input
          ref={ref}
          {...restProps}
          type={type}
          className={classNameInput}
        />
        <span
          className="cursor-pointer bg-transparent"
          style={{ position: 'absolute', right: '15px', top: '5px' }}
          onClick={toggleType}>
          <EyeOutlined className="bg-transparent color-primary" />
          <i
            className={`fa ${type === 'text' ? 'fa-eye-slash' : 'fa-eye'}`}
            aria-hidden="true"></i>
        </span>
      </div>
      {errors && <div className="form-control-invalid__msg">{errors}</div>}
    </>
  );
});

InputPassword.displayName = 'InputPassword';

InputPassword.defaultProps = {
  className: '',
};

export default InputPassword;
