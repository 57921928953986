import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = React.forwardRef((props, ref) => {
  const { label, errors, ...restProps } = props;

  return (
    <>
      <label className="form-group form-check">
        <input
          ref={ref}
          type="checkbox"
          className="form-check-input"
          {...restProps}
        />
        {label && <div className="form-check-label">{label}</div>}
      </label>
      {errors && (
        <div className="form-control-invalid__msg form-control-invalid__msg--check">
          {errors}
        </div>
      )}
    </>
  );
});

Checkbox.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.string,
};

Checkbox.displayName = 'Checkbox';
export default Checkbox;
