import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingGetUserInfo: false,
  isLoadedUserInfo: false,
  currentUser: undefined,
  currentUserRef: '',
  errorMessage: null,
  isShowModalSignIn: false,
  isShowModalSignUp: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.currentUser = action.payload;
    },
    updateCurrentUser: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...(action.payload || {}),
      };
    },
    removeUser: (state) => {
      state.currentUser = null;
      state.isLoadedUserInfo = false;
      state.currentRef = null;
    },
    showModalSingIn: (state) => {
      state.isShowModalSignIn = true;
    },
    hideModalSingIn: (state) => {
      state.isShowModalSignIn = false;
    },
    showModalSingUp: (state) => {
      state.isShowModalSignUp = true;
    },
    hideModalSingUp: (state) => {
      state.isShowModalSignUp = false;
    },
    updateAvatarUser: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        avatar_url: action.payload,
      };
    },
    updateHiddenUser: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        is_hidden: action.payload,
      };
    },
    updateCurrentUserRef: (state, action) => {
      state.currentUserRef = action.payload;
    },
  },
  extraReducers: () => {
    // builder.addCase(getUserDetail.pending, (state) => {
    //   state.isLoadingGetUserInfo = true;
    // });
    // builder.addCase(getUserDetail.fulfilled, (state, action) => {
    //   state.isLoadingGetUserInfo = false;
    //   state.isLoadedUserInfo = true;
    //   state.currentUser = {
    //     ...state.currentUser,
    //     ...action.payload,
    //   };
    // });
    // builder.addCase(getUserDetail.rejected, (state) => {
    //   state.isLoadingGetUserInfo = false;
    //   state.isLoadedUserInfo = true;
    // });
  },
});

// export const getUserDetail = createAsyncThunk(
//   'user/info',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await UserService.getUserDetail();

//       if (response.data) {
//         return response.data;
//       } else {
//         return rejectWithValue('Fail to get user detail');
//       }
//     } catch (err) {
//       return rejectWithValue('Fail to get user detail');
//     }
//   },
// );

export const {
  storeUser,
  removeUser,
  showModalSingIn,
  hideModalSingIn,
  showModalSingUp,
  hideModalSingUp,
  updateAvatarUser,
  updateHiddenUser,
  updateCurrentUserRef,
  updateCurrentUser,
} = authSlice.actions;
export const selectAuth = (state) => state.auth;

export const { actions } = authSlice;
export default authSlice.reducer;
