import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/lib/locale/vi_VN';

import { Provider } from 'react-redux';
import { store } from '@store/index';

import App from './App';

import './I18Next';
import '@assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import variablesColor from './assets/scss/_variable.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider
        locale={vi_VN}
        theme={{
          token: {
            colorPrimary: variablesColor.colorPrimary,
          },
        }}>
        <App />
        <ToastContainer autoClose={3000} hideProgressBar />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
);
