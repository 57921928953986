import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '@containers/Layout/MainLayout';
import Notfound from '@screens/NotFound';
import PublicRoute from './PublicRoute';
import { PublicRoutes } from './router.config';
import { history } from '@/utils';

const Router = () => {
  useEffect(() => {}, []);

  return (
    <Routes history={history}>
      <Route path="/" element={<MainLayout />}>
        {(PublicRoutes || []).map((route, index) => {
          return (
            <Route
              key={route.path}
              path={route.path || index}
              element={
                <PublicRoute
                  roles={route.roles}
                  path={route.path}
                  component={route.component}
                  title={route.title}
                />
              }
            />
          );
        })}
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
};

export default Router;
