export const TOKEN_KEY = 'access_token';
export const BALANCE_KEY = 'balance_user';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ROLE_KEY = 'roles';
export const BROADCAST_CHANNEL_KEY = 'BROADCAST_CHANNEL_KEY';

export const PAGE_URL = {
  HOME: '/',
  ABOUT: '/about',
  PRODUCT: '/product',
  NEWS: '/news',
  CONTACT: '/contact',
};

export const VALIDATE_FORM = {
  MAX_LENGTH_INPUT: 256,
  MAX_LENGTH_TEXTAREA: 2048,
  MAX_LENGTH_PASSWORD: 20,
};

export const REGEX_APP = {
  REGEX_PHONE_NUMBER: /^0\d{9,11}$/,
  REGEX_EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
  REGEX_NOT_CONTAIN_ALL_SPACES: /^.*\S.*$/,
  REGEX_INTEGER: /^[1-9]\d*$/,
};

export const PRODUCT_LIST = {
  Magnet_Axiom: 'Magnet Axiom',
  Encase_Forensic: 'Encase Forensic',
  Oxygen_Forensic: 'Oxygen Forensic',
  MD_Video: 'MD-Video',
  MD_Next_MD_Red: 'MD-Next + MD Red',
  PC_3000_Series: 'PC-3000 Series (Portable, Express, SAS, Mobile, Flash)',
  Metasploit: 'Metasploit',
  OSINT: 'OSINT',
  Chainalysis_Reactor: 'Chainalysis Reactor',
  Interrogation_System: 'Interrogation System',
  Video_Insight: 'Video Insight',
  UAV_Solutions: 'UAV Solutions',
  HBS_System: 'HBS System',
  Neo_Insight: 'Neo Insight',
  Neo_Collect: 'Neo Collect',
  CRM: 'CRM',
  HRM: 'HRM',
  EMS: 'EMS',
};

export const PRODUCT_LIST_URL = {
  Magnet_Axiom: 'magnet-axiom',
  Encase_Forensic: 'encase-forensic',
  Oxygen_Forensic: 'oxygen-forensic',
  MD_Video: 'md-video',
  MD_Next_MD_Red: 'md-next-md-red',
  PC_3000_Series: 'pc-3000-series',
  Metasploit: 'metasploit',
  OSINT: 'osint',
  Chainalysis_Reactor: 'chainalysis-reactor',
  Interrogation_System: 'interrogation-system',
  Video_Insight: 'video-insight',
  UAV_Solutions: 'uav-solutions',
  HBS_System: 'hbs-system',
  Neo_Insight: 'neo-insight',
  Neo_Collect: 'neo-collect',
  CRM: 'crm',
  HRM: 'hrm',
  EMS: 'ems',
};
