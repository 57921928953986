import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import { pick } from 'lodash';

import rootReducer from './rootReducer';
import localforage from 'localforage';

// middleware
import thunk from 'redux-thunk';

// logger
import logger from 'redux-logger';
import { AuthUtil } from '@/utils';

// root reducer

let whitelistTransform = createTransform((inboundState, key) => {
  if (key === 'auth') {
    if (AuthUtil.getToken()) {
      return pick(inboundState, ['currentUser']);
    }

    return {};
  } else {
    return inboundState;
  }
});

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['auth'], // Keep store when F5 page
  transforms: [whitelistTransform],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

export default store;
