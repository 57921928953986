import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';

import { usePageTitle } from '@hooks';

const PublicRoute = ({ component: Component, restrict, ...rest }) => {
  const { t } = useTranslation();
  const { pageTitle } = usePageTitle();

  return (
    <DocumentTitle title={t(pageTitle)}>
      <Component {...rest} />
    </DocumentTitle>
  );
};

export default PublicRoute;

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
  restrict: PropTypes.bool,
};
