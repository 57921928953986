import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './slices/Auth';
import configReducer from './slices/Config';

const appReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
});

// For reset all store using: dispatch({ type: RESET_STORE_ACTION });
export const RESET_STORE_ACTION = 'RESET_STORE';
const rootReducer = (state, action) => {
  if (action.type === RESET_STORE_ACTION) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
