import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import InputPassword from './InputPassword';

const Input = React.forwardRef((props, ref) => {
  const { errors, className, showIconCopy, ...restProps } = props;

  const classNameInput = useMemo(() => {
    if (!errors) return className;

    return className + ' form-control-invalid';
  }, [className, errors]);

  const handleCopy = (event) => {
    let textCopy = '';

    if (Array.from(event.target?.classList).includes('copylink')) {
      textCopy = event.target.previousElementSibling.value;
    } else {
      textCopy =
        event.target?.parentElement?.previousElementSibling?.value || '';
    }

    navigator.clipboard.writeText(textCopy);
    toast.success('Copy success');
  };

  return (
    <>
      <input
        autoComplete="off"
        ref={ref}
        {...restProps}
        className={classNameInput}
      />
      {showIconCopy && (
        <span onClick={handleCopy} className="copylink pointer">
          <i className=" fa fa-copy" aria-hidden="true"></i>
        </span>
      )}
      {errors && <div className="form-control-invalid__msg">{errors}</div>}
    </>
  );
});

Input.displayName = 'Input';
Input.Password = InputPassword;

Input.defaultProps = {
  className: '',
};

export default Input;
