import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_URL, PRODUCT_LIST, PRODUCT_LIST_URL } from '@/config/AppConst';
import LogoImage from '@assets/images/logo.png';
import { MenuOutlined } from '@ant-design/icons';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const wrapperRef = useRef(null);

  const scrollToId = (targetId) => {
    console.log(targetId, 'targetId');
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate(`${PAGE_URL.HOME}`, {
        state: {
          scroll: 'section-about',
        },
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 5;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition > headerHeight) {
        setToggleMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="content">
            <div
              className="logo pointer"
              onClick={() => navigate(`${PAGE_URL.HOME}`, {})}>
              <img src={LogoImage} alt="mhh" />
            </div>
            <div ref={wrapperRef}>
              <div className={`menu ${toggleMenu ? 'is-mobile' : ''}`}>
                <ul className="menu-list">
                  <li className="menu-item">
                    <a
                      className={
                        location?.pathname === PAGE_URL.ABOUT
                          ? 'active menu-link'
                          : 'menu-link'
                      }
                      onClick={() => scrollToId('section-about')}>
                      ABOUT US
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      className={
                        location?.pathname === PAGE_URL.PRODUCT
                          ? 'active menu-link'
                          : 'menu-link'
                      }>
                      PRODUCTS
                    </a>
                    <div className="menu-mega">
                      <div className="content">
                        <div className="block">
                          <div className="name">Digital Forensics</div>
                          <div className="list">
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Magnet_Axiom}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Magnet_Axiom,
                                    },
                                  },
                                )
                              }>
                              Magnet Axiom
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Encase_Forensic}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Encase_Forensic,
                                    },
                                  },
                                )
                              }>
                              Encase Forensic
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Oxygen_Forensic}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Oxygen_Forensic,
                                    },
                                  },
                                )
                              }>
                              Oxygen Forensic
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.MD_Video}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.MD_Video,
                                    },
                                  },
                                )
                              }>
                              MD-Video
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.MD_Next_MD_Red}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.MD_Next_MD_Red,
                                    },
                                  },
                                )
                              }>
                              MD-Next + MD Red
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.PC_3000_Series}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.PC_3000_Series,
                                    },
                                  },
                                )
                              }>
                              PC-3000 Series (Portable, Express, SAS, Mobile,
                              Flash)
                            </div>
                          </div>
                        </div>
                        <div className="block">
                          <div className="name">Cyber Security</div>
                          <div className="list">
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Metasploit}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Metasploit,
                                    },
                                  },
                                )
                              }>
                              Metasploit
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.OSINT}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.OSINT,
                                    },
                                  },
                                )
                              }>
                              OSINT
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Chainalysis_Reactor}`,
                                  {
                                    state: {
                                      productName:
                                        PRODUCT_LIST.Chainalysis_Reactor,
                                    },
                                  },
                                )
                              }>
                              Chainalysis Reactor
                            </div>
                          </div>
                        </div>
                        <div className="block">
                          <div className="name">Professional Solutions</div>
                          <div className="list">
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Interrogation_System}`,
                                  {
                                    state: {
                                      productName:
                                        PRODUCT_LIST.Interrogation_System,
                                    },
                                  },
                                )
                              }>
                              Interrogation System
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Video_Insight}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Video_Insight,
                                    },
                                  },
                                )
                              }>
                              Video Insight
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.UAV_Solutions}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.UAV_Solutions,
                                    },
                                  },
                                )
                              }>
                              UAV Solutions
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.HBS_System}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.HBS_System,
                                    },
                                  },
                                )
                              }>
                              HBS System
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Neo_Insight}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Neo_Insight,
                                    },
                                  },
                                )
                              }>
                              Neo Insight
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.Neo_Collect}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.Neo_Collect,
                                    },
                                  },
                                )
                              }>
                              Neo Collect
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.CRM}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.CRM,
                                    },
                                  },
                                )
                              }>
                              CRM
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.HRM}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.HRM,
                                    },
                                  },
                                )
                              }>
                              HRM
                            </div>
                            <div
                              className="item"
                              onClick={() =>
                                navigate(
                                  `${PAGE_URL.PRODUCT}?search=${PRODUCT_LIST_URL.EMS}`,
                                  {
                                    state: {
                                      productName: PRODUCT_LIST.EMS,
                                    },
                                  },
                                )
                              }>
                              EMS
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="menu-item">
                    <a
                      className={
                        location?.pathname === PAGE_URL.NEWS
                          ? 'active menu-link'
                          : 'menu-link'
                      }
                      onClick={() => navigate(`${PAGE_URL.NEWS}`, {})}>
                      NEWS
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      className={
                        location?.pathname === PAGE_URL.CONTACT
                          ? 'active menu-link'
                          : 'menu-link'
                      }
                      onClick={() => scrollToId('footer-contact')}>
                      CONTACT
                    </a>
                  </li>
                </ul>
              </div>
              <MenuOutlined
                className="menu-mobile"
                style={{ color: '#fff', fontSize: '25px' }}
                onClick={() => {
                  setToggleMenu(!toggleMenu);
                }}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default React.memo(Header);
